<template>
  <NavQuestion
    :number="number"
    :subHeading="'By clicking submit you are handing over the account to your client, they will then be asked to set a password, agree to the service terms, check the information you have added on their behalf and complete any remaining information.'"
    v-bind:heading="'Account Handover'"
    v-bind:background="'bg-basket'"
    v-bind:questionOptions="true"
  >
    <template v-slot:contents>
      <div class="card-form">
        <div class="row mt30">
          <div class="col-lg-6 col-md-6 col-sm-12">
            <div class="input-container">
              <input
                v-model="form.email"
                type="text"
                id="first"
                class="input-standard-grey validation"
                :class="{ error: error.email }"
                required
                placeholder="Client Email"
              />
              <label for="first" :class="{ 'error-text': error.email }">
                {{ error.email || 'Client Email' }}
              </label>
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12">
            <div class="input-container">
              <input
                v-model="form.phone"
                type="text"
                id="last"
                class="input-standard-grey width-full validation"
                :class="{ error: error.phone }"
                required
                placeholder="Client Mobile Number"
              />
              <label for="last" :class="{ 'error-text': error.phone }">{{
                error.phone || 'Client Mobile Number'
              }}</label>
            </div>
          </div>
        </div>

        <div class="notification-text">
          <p>
            On submission
            {{ firstWord(personalDetails.full_name, false, false) }} will be
            sent an email and a text message, each contain a link that will
            allow {{ himHer(personalDetails.sex) }} to complete the process. The
            account username will be the above email address, on following the
            link a password can be set. No future access to the account will be
            available to you.
          </p>
        </div>
      </div>
      <BaseModalSmall
        v-show="show.success"
        :show="show.success"
        :no-slide="true"
        :isValidated="true"
        @confirm="logout"
      >
        <template v-slot:header>Handover Complete</template>
        <template v-slot:body>
          Your client has been contacted regarding this account, they must now
          follow the link provided, and set a password for the account.
          <span
            v-if="personalDetails.partner_full_name && !personalDetails.partner"
          >
            You will have the option to create a mirror will for
            {{ personalDetails.partner_full_name }} in your broker account
          </span>
        </template>
        <template v-slot:button>Logout</template>
      </BaseModalSmall>
    </template>
    <template v-slot:navButtons>
      <NavQuestionButtons
        v-bind:validated="validated"
        v-bind:forwardText="'Submit'"
        v-bind:backLink="backLink"
        v-bind:loading="loading"
        v-bind:forwardDisabled="loading"
        @forwardClick="submit"
      ></NavQuestionButtons>
    </template>
  </NavQuestion>

  <!--
  <NavQuestion
    :number="number"
    :subHeading="'By clicking submit you are handing over the account to your client, they will then be asked to set a password, agree to the service terms, check the information you have added on their behalf and complete any remaining information.'"
    v-bind:heading="'Account Handover'"
    v-bind:background="'bg-basket'"
    v-bind:questionOptions="true"
  >
    <template v-slot:contents>
      <div class="card-form">
        <div class="row mt30">
          <div class="col-lg-6 col-md-6 col-sm-12">
            <div class="input-container">
              <input
                v-model="form.email"
                type="text"
                id="first"
                class="input-standard-grey validation"
                :class="{ error: error.email }"
                required
                placeholder="Client Email"
              />
              <label for="first" :class="{ 'error-text': error.email }">
                {{ error.email || 'Client Email' }}
              </label>
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12">
            <div class="input-container">
              <input
                v-model="form.phone"
                type="text"
                id="last"
                class="input-standard-grey width-full validation"
                :class="{ error: error.phone }"
                required
                placeholder="Client Mobile Number"
              />
              <label for="last" :class="{ 'error-text': error.phone }">{{
                error.phone || 'Client Mobile Number'
              }}</label>
            </div>
          </div>
        </div>

        <div class="notification-text">
          <p>
            On submission
            {{ firstWord(personalDetails.full_name, false, false) }}
            will be sent an email and a text message, each contain a link that
            will allow
            {{ himHer(personalDetails.sex) }} to complete the process. The
            account username will be the above email address, on following the
            link a password can be set. No future access to the account will be
            available to you.
          </p>
        </div>
      </div>
      <BaseModalSmall
        v-show="show.success"
        :show="show.success"
        :no-slide="true"
        :isValidated="true"
        @confirm="logout"
      >
        <template v-slot:header>Handover Complete</template>
        <template v-slot:body>
          Your client has been contacted regarding this account, they must now
          follow the link provided, and set a password for the account.

          <button
            style="width: 100%; border-radius: 0"
            v-if="personalDetails.partner_full_name && !personalDetails.partner"
            @click="startPartnerWill"
            class="btn btn--dark mt30 mb30"
          >
            Start Partner Will
          </button>
          <p
            v-if="personalDetails.partner_full_name && !personalDetails.partner"
            class="divider"
          >
            Or
          </p>
        </template>
        <template v-slot:button>Logout</template>
      </BaseModalSmall>
    </template>
    <template v-slot:navButtons>
      <NavQuestionButtons
        v-bind:validated="validated"
        v-bind:forwardText="'Submit'"
        v-bind:backLink="backLink"
        v-bind:loading="loading"
        v-bind:forwardDisabled="loading"
        @forwardClick="submit"
      ></NavQuestionButtons>
    </template>
  </NavQuestion>
  -->
</template>

<script>
import NavQuestionButtons from '@/common/ui/NavQuestionButtons'
import NavQuestion from '@/common/ui/NavQuestion'
import { http } from '@/services'
import { functions } from '@/common/mixins/functions'
import { TextFormat } from '../../../../common/mixins/TextFormat'
import { willStringHelpers } from '../../../../common/mixins/willStringHelpers'
import BaseModalSmall from '../../../../common/ui/BaseModalSmall'

export default {
  name: 'AccountHandoverPage',
  mixins: [functions, TextFormat, willStringHelpers],
  components: {
    BaseModalSmall,
    NavQuestionButtons,
    NavQuestion
  },
  mounted() {
    if (this.$store.getters.user) {
      this.form.email = this.$store.getters.user.email
    }
    if (this.personalDetails && this.personalDetails.phone)
      this.form.phone = this.personalDetails.phone
  },
  data() {
    return {
      form: {
        email: null,
        phone: null
      },
      error: {
        email: null,
        phone: null
      },
      loading: false,
      show: {
        success: false
      }
    }
  },
  watch: {},
  computed: {
    tempAccount() {
      return this.user && this.user.temp_account && this.user.change_password
    },
    number() {
      if (this.people.length) return '2'
      return '1'
    },
    personalDetails() {
      return this.$store.getters.personalDetails
    },
    user() {
      return this.$store.getters.user
    },
    validated() {
      return !!(this.validEmail(this.form.email) && this.form.phone)
    },
    people() {
      return this.$store.getters.shareBrokerPeopleOptions
    },
    backLink() {
      if (this.people.length) return '/handover/share'
      return '/home'
    }
  },
  methods: {
    submit() {
      this.loading = true
      this.clearErrors()
      if (this.$store.getters.handoverBrokerContactEmail)
        this.form.broker_contact_email = this.$store.getters.handoverBrokerContactEmail
      http
        .post('/broker/api/v2/account_handover', this.form)
        .then((response) => {
          this.show.success = true
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          console.log(error.response.data)
          this.handleErrors(error.response.data)
        })
    },
    goToBrokerArea() {
      var base =
        process.env.NODE_ENV === 'production'
          ? 'https://broker.mylastwill.co.uk/'
          : 'http://127.0.0.1:8080/'
      window.open(
        base + 'handover_created/' + this.$store.getters.user.email,
        '_self'
      )
    },
    handleErrors (errors) {
      if ('email' in errors) this.error.email = errors.email[0]
      if ('phone' in errors) this.error.phone = errors.phone[0]
    },
    clearErrors() {
      this.error.email = null
      this.error.phone = null
    },
    logout() {
      this.goToBrokerArea()

      if (
        this.personalDetails.partner_full_name &&
        this.personalDetails.partner
      ) {
        this.clearHandover()
      }
      this.$notification.removeAll()
      this.$store.dispatch('logout')
      this.$notification.success('You have been logged out')
    },
    clearHandover() {
      localStorage.removeItem('handoverUser')
      localStorage.removeItem('handoverPartner')
    },
    startPartnerWill() {
      const parsedUser = JSON.stringify({
        email: this.$store.getters.user.email,
        name: this.$store.getters.personalDetails.full_name
      })
      localStorage.setItem('handoverUser', parsedUser)

      const parsedPartner = JSON.stringify({
        email: this.$store.getters.partner.email || null,
        name: this.$store.getters.partner.full_name
      })
      localStorage.setItem('handoverPartner', parsedPartner)
      this.logout()

      this.goToBrokerArea()
    }
  }
}

/*

import NavQuestionButtons from '@/common/ui/NavQuestionButtons'
import NavQuestion from '@/common/ui/NavQuestion'
import { http } from '@/services'
import { functions } from '@/common/mixins/functions'
import { TextFormat } from '../../../../common/mixins/TextFormat'
import { willStringHelpers } from '../../../../common/mixins/willStringHelpers'
import BaseModalSmall from '../../../../common/ui/BaseModalSmall'
export default {
  name: 'AccountHandoverPage',
  mixins: [functions, TextFormat, willStringHelpers],
  components: {
    BaseModalSmall,
    NavQuestionButtons,
    NavQuestion
  },
  mounted() {
    if (this.$store.getters.user) {
      this.form.email = this.$store.getters.user.email
    }
    if (this.personalDetails && this.personalDetails.phone)
      this.form.phone = this.personalDetails.phone
  },
  data() {
    return {
      form: {
        email: null,
        phone: null
      },
      error: {
        email: null,
        phone: null
      },
      loading: false,
      show: {
        success: false
      }
    }
  },
  watch: {},
  computed: {
    tempAccount() {
      return this.user && this.user.temp_account && this.user.change_password
    },
    number() {
      if (this.people.length) return '2'
      return '1'
    },
    personalDetails() {
      return this.$store.getters.personalDetails
    },
    user() {
      return this.$store.getters.user
    },
    validated() {
      return !!(this.validEmail(this.form.email) && this.form.phone)
    },
    people() {
      return this.$store.getters.shareBrokerPeopleOptions
    },
    backLink() {
      if (this.people.length) return '/handover/share'
      return '/home'
    }
  },
  methods: {
    submit() {
      this.loading = true
      if (this.$store.getters.handoverBrokerContactEmail)
        this.form.broker_contact_email = this.$store.getters.handoverBrokerContactEmail
      http
        .post('/broker/api/v2/account_handover', this.form)
        .then((response) => {
          this.show.success = true
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          console.log(error.response.data)
        })
    },
    logout() {
      if (
        this.personalDetails.partner_full_name &&
        this.personalDetails.partner
      ) {
        this.clearHandover()
      }
      this.$notification.removeAll()
      this.$store.dispatch('logout')
      this.$notification.success('You have been logged out')
    },
    clearHandover() {
      localStorage.removeItem('handoverUser')
      localStorage.removeItem('handoverPartner')
    },
    startPartnerWill() {
      const parsedUser = JSON.stringify({
        email: this.$store.getters.user.email,
        name: this.$store.getters.personalDetails.full_name
      })
      localStorage.setItem('handoverUser', parsedUser)
      const parsedPartner = JSON.stringify({
        email: this.$store.getters.partner.email || null,
        name: this.$store.getters.partner.full_name
      })
      localStorage.setItem('handoverPartner', parsedPartner)
      this.logout()
      this.$router.push('/broker_create_user')
    }
  }
}

 */
</script>

<style scoped>
.width-full {
  max-width: 100%;
}
.check {
  display: inline-block;
  font-size: 15px;
  margin-left: 10px;
}
.error-text {
  font-size: 15px;
  color: #e56781;
  white-space: nowrap;
}
.error {
  color: #e56781;
  border-top: 1px solid #e56781 !important;
  border-left: 1px solid #e56781 !important;
  border-bottom: 1px solid #e56781 !important;
  border-right: 5px solid #e56781 !important;
}
.card-form {
  border-radius: 10px 10px 10px 10px;
  -webkit-box-shadow: 10px 10px 45px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 10px 10px 45px 0px rgba(0, 0, 0, 0.1);
  margin-top: 0px;
  margin-bottom: 0px;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  padding: 50px;
  background-color: white;
}
.notification-text {
  background-color: beige;
  padding: 30px;
  border-radius: 10px;
}
@media screen and (max-width: 570px) {
  .card-form {
    padding: 20px;
    border-radius: 0;
  }
  .notification-text {
    padding: 20px;
    border-radius: 0;
  }
}
.heading {
  font-family: 'Vollkorn', serif;
  line-height: 1.3;
  margin: 0;
  font-weight: 600;
  color: #2f2c2c;
}
.divider {
  width: 100%;
  text-align: center;
  font-weight: bolder;
  font-size: 24px;
}
</style>
